import './AppInterface.css';

import { useEffect, useContext } from 'react';

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

import { Alert } from '@mui/material'; 
import { AppContext } from '../../components/AppContext';
import MCLanding from '../../pages/MCLanding/MCLanding';

//Material UI
import Box from '@mui/material/Box';
import Fade from '@mui/material/Fade';

// const welcome_message = "Welcome to PixelsAI, ask me Pixels releated questions"

function AppInterface() {

  const { global_app, updateGlobalVariables } = useContext(AppContext);
  
  useEffect(() => {
    //Managing App Level Alerts
    // console.log("THIS IS THE NEW VALUE: ", global_app?.control.alerts?.global_alert?.show)

    setTimeout(() => {
      let assign_alert = {
          show: false,
          severity: "error",
          message: "-"
          };
      if (global_app?.control?.alerts) {
          global_app.control.alerts.global_alert = assign_alert;
          }
      updateGlobalVariables(global_app);
      }, 3000);
  }, [global_app?.control.alerts?.global_alert?.show]);

  return (

    <div className="AppInterface">
      <div className={true ? "AlertWrapper" : "AlertWrapperInactive"}>
        <Box>
          <Fade in={global_app?.control.alerts?.global_alert?.show}>
            <Alert severity={global_app?.control.alerts?.global_alert?.severity}>
                <p>
                  {global_app?.control.alerts?.global_alert?.message}
                </p>
            </Alert >
          </Fade>
        </Box>
      </div>
      <MCLanding />
    </div>
  );
}

export default AppInterface;
