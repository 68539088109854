
import React from 'react';
import { AppContext } from './AppContext';

export const AppContextProvider = ({ children }) => {

  const [global_app, setGlobalApp] = React.useState({
    global_app: {
      // Active User Profile
      // SideBar Status
      sideBarStatus: true,
      control: {
        alerts: {
          global_alert: {
            message: "Error!",
            severity:"error",
            show: false
          }
        },
        loadingPage: true,
      },

      //Components Context: 
      // Login
      login: {
        loading: false
      }
    }
  });

  const updateGlobalVariables = newVariables => {
    setGlobalApp(prevVariables => ({
      ...prevVariables,
      ...newVariables,
    }));
  };

  return (
    <AppContext.Provider value={
      { ...global_app, updateGlobalVariables }}>
      {children}
    </AppContext.Provider>
  );
};