import './App.css';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppContextProvider } from './components/AppContextProvider';
import AppInterface from './interfaces/AppInterface/AppInterface';

function App() {

  // function RedirectToLogin() {
  //   const navigate = useNavigate();
  //   useEffect(() => {
  //     navigate('/login');
  //   }, [navigate]);
  
  //   return null;
  // }

  // function RedirectToHome() {
  //   const navigate = useNavigate();
  //   useEffect(() => {
  //     navigate('/');
  //   }, [navigate]);
  
  //   return null;
  // }
  return (
    <AppContextProvider>
      <AppInterface />
    </AppContextProvider>

  );
}

export default App;
