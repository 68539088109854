import React from 'react';
import './MCLanding.css'
import FadeInSection from '../../components/FadeInSection';
const MCLanding = () => {

    return (
        <div className="MCLanding">
            <div className="websiteWrapper">

                <div className="HeroWrapper">

                    <div className="HeroTop">
                        <div className="HeroTopRight HeroTopRightLogoText LogoPaper">
                            <img src="./images/logo.png" className="HeroTopLogo" alt="Logo Agente.tech" />
                            <div className="HeroBoxLogo">
                                <h2>Agente.tech</h2>
                            </div>
                        </div>
                    </div>

                    <div className="HeroUp">
                        <FadeInSection>
                            <div className="HeroUpLeft HeroBox HeroBoxLeft inner-text-title-up">
                                Revoluciona tu negocio con Inteligencia Artificial
                            </div>
                        </FadeInSection>
                    </div>
                    
                    {/* <div className="HeroMiddle">
                        <div className="HeroMiddleRight HeroBox HeroBoxRight "> 
                            <img src="./images/hero-asset-1.png" className="HeroAssetImage" alt="Develop Image" />
                        </div>
                    </div> */}

                    <div className="HeroDown">
                        <FadeInSection>
                            <div className="HeroDownRight HeroBox HeroBoxRight inner-text-title-down"> 
                                Desarolla agentes con inteligencia artificial personalizados para transformar tus datos en decisiones estratégicas.
                            </div>
                        </FadeInSection>
                    </div>

                </div> 

                <div className="Sec1Wrapper">


                        <div className="ServiceSec ServiceSecTextTittle HeroBoxLogo">
                            Nuestros Servicios
                        </div>

                    <div className="ServiceSec ServiceSecText">
                        Ofrecemos soluciones integrales en inteligencia artificial, diseñadas para satisfacer las necesidades específicas de tu empresa.
                    </div>

                    <FadeInSection>
                        <div className="SecUp">
                            <div className="SecUpLeft HeroBox HeroBoxLeft inner-text-title-sec">
                                Consultoría en IA
                            </div>
                        </div>
                    </FadeInSection>

                    <FadeInSection>
                        <div className="SecDown">
                            <div className="SecDownRight HeroBox HeroBoxRight inner-text-title-sec"> 
                                Identificamos oportunidades y diseñamos estrategias personalizadas para integrar la inteligencia artificial en tu negocio, mejorando la eficiencia y poniendo tus datos a tu alcance.
                            </div>
                        </div>
                    </FadeInSection>

                    <FadeInSection>
                        <div className="SecUp">
                            <div className="SecUpLeft HeroBox HeroBoxLeft inner-text-title-sec">
                                Desarrollo Personalizado
                            </div>
                        </div>
                    </FadeInSection>

                    <FadeInSection>
                        <div className="SecDown">
                            <div className="SecDownRight HeroBox HeroBoxRight inner-text-title-sec"> 
                                Creamos agentes de IA a medida, adaptados a los datos y objetivos de tu empresa, garantizando soluciones únicas y efectivas.
                            </div>
                        </div>
                    </FadeInSection>

                    <FadeInSection>
                        <div className="SecUp">
                            <div className="SecUpLeft HeroBox HeroBoxLeft inner-text-title-sec">
                                Integración y Soporte
                            </div>
                        </div>
                    </FadeInSection>

                    <FadeInSection>
                        <div className="SecDown">
                            <div className="SecDownRight HeroBox HeroBoxRight inner-text-title-sec"> 
                                Te acompañamos en la implementación de agentes de IA en tus sistemas existentes, proporcionando soporte continuo para asegurar el máximo rendimiento.
                            </div>
                        </div>
                    </FadeInSection>
                </div>

                {/* <div className="Sec2Wrapper">

                </div>

                <div className="Sec2Wrapper">
                    
                </div> */}

                <div className="Footer">
                    <ul className="FooterContent">
                        <li><a href="https://www.linkedin.com/in/manolocap/" target="_blank" rel="noopener noreferrer" className="FooterLink">LinkedIn: Manolo Capilla</a></li>
                        <li><a href="mailto:info@agente.tech" className="FooterEmail">Email: info@agente.tech</a></li>
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default MCLanding